import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";

import svgArrow from "../images/svg/arrow-primary-icon.svg";
import svgTeam from "../images/svg/team-red-icon.svg";
import svgRocket from "../images/svg/rocket-cyan-icon.svg";
import svgIdea from "../images/svg/idea-primary-icon.svg";
import svgWorld from "../images/svg/genious-purple-icon.svg";
import svgPlan from "../images/svg/plan-brown-icon.svg";

export default ({ data }) => (
  <Layout>
    <SEO
      title="About Us"
      keywords={[
        "static",
        "control",
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />

    <Hero
      desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
      desktop={data.bgDesktop.childImageSharp.fluid.src}
      tablet={data.bgTablet.childImageSharp.fluid.src}
      mobile={data.bgMobile.childImageSharp.fluid.src}
      mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
    >
      <h1 className="text-white">Static Power Philippines Inc.</h1>
      <p className="text-white lead">
        Committed to give quality products and services.
      </p>
    </Hero>

    <div className="container space-1 space-2--lg">
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-3">
        <span className="u-label u-label--sm u-label--teal mb-3">About Us</span>
      </div>

      <div className="row align-items-lg-center">
        <div className="col-md-6 order-md-2 mb-5 mb-md-0">
          <div className="mb-4">
            <p>
              Static Power Philippines, Inc. was formally established in May
              2009 by a group of experienced and highly motivated technical
              professionals from various engineering disciplines. After years of
              gaining different experiences and expertise in engineering
              industries, training and education, Static Power was realized with
              the underlying commitment of providing quality products and
              services.
            </p>
            <p>
              We offer optimum solution for a wide range of control systems
              applications. As a system integrator, we promote system
              efficiency, reliability and/improve process performance of various
              industrial, commercial and other institutional establishments.
            </p>
            <p>
              We also engages in the design, modification and upgrading of
              electrical systems, including controls and auto-mation schemes for
              industrial, commercial, and institutional applications. It also
              provides maintenance and repair programs, installations, testing
              and commissioning, systems programming and estimation. Moreover,
              it also engages in offering consultations, sales, contracting and
              provide trainings and seminars.
            </p>
          </div>
        </div>

        <div className="col-md-6 order-md-1">
          <div className="pr-lg-4">
            <Img
              className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
              fluid={data.hajji.childImageSharp.fluid}
              alt="Static Power"
              title="Static Power"
            />
            <Img
              className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
              fluid={data.julius.childImageSharp.fluid}
              alt="Static Power"
              title="Static Power"
            />
          </div>
        </div>
      </div>
    </div>

    <hr className="my-0" />

    <div className="container space-2-top space-3-top--lg">
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
        <span className="u-label u-label--sm u-label--blue mb-3">
          Milestones
        </span>
        <h2 className="h3">Our Journey</h2>
      </div>

      <ul className="u-timeline">
        <li className="u-timeline__item">
          <div className="u-timeline__content">
            <div className="media">
              <span className="u-icon u-icon--primary u-icon--sm u-timeline__icon rounded-circle mr-3">
                <span className="u-icon__inner">09</span>
              </span>
              <div className="media-body">
                <h4 className="h5">The Beginning</h4>
                <p>
                  Incorporated as{" "}
                  <strong>STATIC POWER PHILIPPINES, INC.</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="u-timeline__info">
            <Img
              className="w-sm-50 w-lg-75 mx-lg-auto"
              fluid={data.static.childImageSharp.fluid}
              alt="Static Power"
              title="Static Power"
            />
          </div>
          <div className="u-timeline__spacer"></div>
        </li>

        <li className="u-timeline__item">
          <div className="u-timeline__content">
            <div className="media">
              <span className="u-icon u-icon--primary u-icon--sm u-timeline__icon rounded-circle mr-3">
                <span className="u-icon__inner">10</span>
              </span>
              <div className="media-body">
                <h4 className="h5">COMAP</h4>
                <p>
                  Appointed by <strong>COMAP</strong> as official Distributor of
                  Gen-set and Engine Controllers in Philippines
                </p>
              </div>
            </div>
          </div>
          <div className="u-timeline__info">
            <Img
              className="w-sm-50 w-lg-75 mx-lg-auto"
              fluid={data.comap.childImageSharp.fluid}
              alt="COMAP"
              title="COMAP"
            />
          </div>
          <div className="u-timeline__spacer"></div>
        </li>

        <li className="u-timeline__item">
          <div className="u-timeline__content">
            <div className="media">
              <span className="u-icon u-icon--primary u-icon--sm u-timeline__icon rounded-circle mr-3">
                <span className="u-icon__inner">10</span>
              </span>
              <div className="media-body">
                <h4 className="h5">MEGADUCT</h4>
                <p>
                  Authorized Philippines Distributor of{" "}
                  <strong>MEGADUCT</strong> busduct a leading designer and
                  manufacturer of busway trunking system in Malaysia
                </p>
              </div>
            </div>
          </div>
          <div className="u-timeline__info">
            <Img
              className="w-sm-50 w-lg-75 mx-lg-auto"
              fluid={data.mega.childImageSharp.fluid}
              alt="MEGADUCT"
              title="MEGADUCT"
            />
          </div>
          <div className="u-timeline__spacer"></div>
        </li>

        <li className="u-timeline__item">
          <div className="u-timeline__content">
            <div className="media">
              <span className="u-icon u-icon--primary u-icon--sm u-timeline__icon rounded-circle mr-3">
                <span className="u-icon__inner">15</span>
              </span>
              <div className="media-body">
                <h4 className="h5">GOVERNORS AMERICA CORP.</h4>
                <p>
                  Appointed by <strong>GOVERNORS AMERICA CORP.</strong> as
                  Authorized Philippines Distributor of Engine Control System
                </p>
              </div>
            </div>
          </div>
          <div className="u-timeline__info">
            <Img
              className="w-sm-50 w-lg-75 mx-lg-auto"
              fluid={data.gac.childImageSharp.fluid}
              alt="GOVERNORS AMERICA CORP"
              title="GOVERNORS AMERICA CORP"
            />
          </div>
          <div className="u-timeline__spacer"></div>
        </li>

        <li className="u-timeline__item">
          <div className="u-timeline__content">
            <div className="media">
              <span className="u-icon u-icon--primary u-icon--sm u-timeline__icon rounded-circle mr-3">
                <span className="u-icon__inner">16</span>
              </span>
              <div className="media-body">
                <h4 className="h5">Schneider Electric</h4>
                <p>
                  Appointed Prisma Enclosure Partner by{" "}
                  <strong>Schneider Electric</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="u-timeline__info">
            <Img
              className="w-sm-50 w-lg-75 mx-lg-auto"
              fluid={data.schneider.childImageSharp.fluid}
              alt="Schneider Electric"
              title="Schneider Electric"
            />
          </div>
          <div className="u-timeline__spacer"></div>
        </li>

        <li className="u-timeline__item">
          <div className="u-timeline__content">
            <div className="media">
              <span className="u-icon u-icon--primary u-icon--sm u-timeline__icon rounded-circle mr-3">
                <span className="u-icon__inner">18</span>
              </span>
              <div className="media-body">
                <h4 className="h5">Schneider Electric & Philips Lighting</h4>
                <p>
                  Appointed Panel Board Partner by{" "}
                  <strong>Schneider Electric</strong>
                  <br />
                  Value Added Partner by <strong>Philips Lighting</strong> —
                  Signify
                </p>
              </div>
            </div>
          </div>
          <div className="u-timeline__info">
            <Img
              className="w-sm-50 w-lg-75 mx-lg-auto"
              fluid={data.philips.childImageSharp.fluid}
              alt="Philips Lighting Signify"
              title="Philips Lighting Signify"
            />
          </div>
          <div className="u-timeline__spacer"></div>
        </li>
      </ul>
    </div>
    <div className="container position-relative z-index-2 mb-n9 mb-3">
      <div className="w-md-80 w-lg-60 mx-md-auto">
        <div
          className="bg-primary shadow-lg text-center rounded bg-img-hero p-9"
          // style="background-image: url(../../assets/img/bg/bg2.png);"
        >
          <div className="mb-5">
            <h2 className="h3 text-white">Want to be part of our Journey?</h2>
          </div>
          <Link
            to="/contact-us/"
            className="btn btn-wide btn-light mb-1 mb-sm-0 mr-sm-2"
            activeClassName="active"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>

    <div className="bg-gray-100">
      <div className="container space-2 space-3--lg">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-lg-5 mb-5 mb-lg-0 mt-5">
            <span className="u-label u-label--sm u-label--purple mb-3">
              Mission &amp; Vision
            </span>
            <h2 className="h3">Our Goal</h2>
            <p>
              To be the #1 solutions partner of world-class electrical control
              systems and integration services supporting customer's safety,
              productivity and efficient use of electrical power to aid nation
              building by 2025.
            </p>

            <Img
              className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
              fluid={data.vision.childImageSharp.fluid}
              alt="Mission & Vision"
              title="Mission & Vision"
            />
          </div>

          <div className="col-lg-6">
            <ul className="list-group list-group-borderless">
              <li className="list-group-item bg-gray-100">
                <h3 className="h5 mission">
                  <div className="d-block">
                    <img
                      src={svgArrow}
                      className="mission-img mr-2"
                      alt="satisfaction Guaranteed"
                    />
                    <strong className="h1 font-weight-bold text-danger align-middle mr-1 font-family-charm">
                      S
                    </strong>
                    atisfaction Guaranteed
                  </div>
                </h3>
              </li>
              <li className="list-group-item bg-gray-100">
                <h3 className="h5 mission">
                  <div className="d-block">
                    <img
                      src={svgTeam}
                      className="mission-img mr-2"
                      alt="talent Management"
                    />
                    <strong className="h1 font-weight-bold text-danger align-middle mr-1 font-family-charm">
                      T
                    </strong>
                    alent Management
                  </div>
                </h3>
              </li>
              <li className="list-group-item bg-gray-100">
                <h3 className="h5 mission">
                  <div className="d-block">
                    <img
                      src={svgPlan}
                      className="mission-img mr-2"
                      alt="allied Partner"
                    />
                    <strong className="h1 font-weight-bold text-danger align-middle mr-1 font-family-charm">
                      A
                    </strong>
                    llied Partner
                  </div>
                </h3>
              </li>
              <li className="list-group-item bg-gray-100">
                <h3 className="h5 mission">
                  <div className="d-block">
                    <img
                      src={svgRocket}
                      className="mission-img mr-2"
                      alt="technological Advancement"
                    />
                    <strong className="h1 font-weight-bold text-danger align-middle mr-1 font-family-charm">
                      T
                    </strong>
                    echnological Advancement
                  </div>
                </h3>
              </li>
              <li className="list-group-item bg-gray-100">
                <h3 className="h5 mission">
                  <div className="d-block">
                    <img
                      src={svgIdea}
                      className="mission-img mr-2"
                      alt="innovation"
                    />
                    <strong className="h1 font-weight-bold text-danger align-middle mr-1 ml-1 font-family-charm">
                      I
                    </strong>
                    nnovation
                  </div>
                </h3>
              </li>
              <li className="list-group-item bg-gray-100">
                <h3 className="h5 mission">
                  <div className="d-block">
                    <img
                      src={svgWorld}
                      className="mission-img mr-2"
                      alt="care for Environment & Community"
                    />
                    <strong className="h1 font-weight-bold text-danger align-middle mr-1 font-family-charm">
                      C
                    </strong>
                    are for Environment &amp; Community
                  </div>
                </h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    static: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    hajji: file(relativePath: { eq: "static/hajji.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    julius: file(relativePath: { eq: "static/julius.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    comap: file(relativePath: { eq: "comap/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mega: file(relativePath: { eq: "megaduct/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gac: file(relativePath: { eq: "GAC/logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    schneider: file(relativePath: { eq: "schneider/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    philips: file(relativePath: { eq: "philips/signify-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktopWide: file(relativePath: { eq: "about.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "about.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "about.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "about.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "about.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    vision: file(relativePath: { eq: "vision.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
